<template>
    <section class="h-100">
        <div class="row mx-0 align-items-center py-2 px-2 border-b-gris align-items-center border-l-gris" style="height:54px;">
            <template>
                <div v-if="id_pedido" class="btn-action border mx-2 cr-pointer" @click="imprimir">
                    <i class="icon-printer hover-icons f-20" />
                </div>

                <p v-show="[4].indexOf(pedido.estado) > -1" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Entregado</p>
                <p v-show="[27].indexOf(pedido.estado) > -1" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Cancelado Por Admin</p>
                <p v-show="pedido.estado === 25" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Rechazado</p>
                <p v-show="pedido.estado === 26 && pedido.cancelo_cliente === false" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Cancelado Por {{$config.vendedor}}</p>
                <p v-show="pedido.estado === 26 && pedido.cancelo_cliente" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Cancelado por el {{$config.cliente}}</p>
                <p v-show="pedido.estado === 32" class="text-general"><b>ID. {{ pedido.id }} </b>Pedido Con Reclamo finalizado</p>


                <div class="d-middle ml-auto">
                    <div v-if="pedido.calificacion === null && (pedido.estado === 4 || pedido.estado ===32)" class="cr-pointer" @click="open_calificaciones">
                        <span class="ml-3 text-general">Pedido sin calificar</span>
                        <i class="icon-star text-muted mx-2" />
                    </div>
                    <div v-else-if="pedido.calificacion !== null && (pedido.estado === 4 || pedido.estado ===32)" class="cr-pointer ml-5" @click="open_calificaciones">
                        <span class="ml-3">{{ redondearNumero(pedido.calificacion,1) }}</span>
                        <i class="icon-star text-warning mx-2" />
                    </div>
                    <!-- Estados -->

                    <el-tooltip placement="top" effect="light" content="Click para ver información de cancelación" class="cr-pointer">
                        <div v-show="[26].indexOf(pedido.estado) > -1" class="d-middle border-black text-general px-1 br-8 f-15" style="height:32px;" @click="irDetalleCancelado">
                            <i class="text-general f-18 icon-account-remove-outline" />
                            <p>Cancelado</p>
                            <p>({{ pedido.updated_at | helper-fecha('DD/MMM/Y - hh:mm a') }})</p>
                        </div>
                    </el-tooltip>

                    <el-tooltip placement="top" effect="light" content="Click para ver información de cancelación" class="cr-pointer">
                        <div v-show="[27].indexOf(pedido.estado) > -1" class="d-middle border-black text-general px-1 br-8 f-15" style="height:32px;" @click="irDetalleCancelado">
                            <i class="text-general f-18 icon-cancel-circled-outline" />
                            <p>Cancelado</p>
                            <p>({{ pedido.updated_at | helper-fecha('DD/MMM/Y - hh:mm a') }})</p>
                        </div>
                    </el-tooltip>

                    <el-tooltip placement="top" effect="light" content="Click para ver información de rechazado" class="cr-pointer">
                        <div v-show="pedido.estado === 25" class="d-middle border-black px-1 br-8 f-15 text-general" style="height:32px;" @click="irDetalleRechazado">
                            <i class="f-18 icon-playlist-remove" />
                            <p>Rechazado</p>
                            <p>({{ pedido.updated_at | helper-fecha('DD/MMM/Y - hh:mm a') }})</p>
                        </div>
                    </el-tooltip>
                    <!-- Estados -->
                    <el-tooltip v-if="id_pedido" placement="bottom" content="Historial de devolucion de productos" effect="light">
                        <div class="btn-action border mx-2" @click="historialDevueltos">
                            <i class="icon-history f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="id_pedido" placement="bottom" content="Historial de productos eliminados" effect="light">
                        <div class="btn-action border" @click="historialEliminados">
                            <i class="icon-delete-clock-outline f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="pedido.id" class="item" effect="light" :content="`Hablar con el ${$config.cliente}`" placement="bottom">
                        <el-badge :value="pedido.cant_mensajes" class="item mx-2 btn-action border">
                            <i class="icon-message-text-outline cr-pointer f-17" @click="verChat" />
                        </el-badge>
                    </el-tooltip>
                    <el-tooltip v-if="pedido.id && pedido.id_pedido" class="item" effect="light" :content="`Pedido Directo al ${$config.cliente}`" placement="bottom">
                        <div class="btn-action border" @click="verChat">
                            <i class="icon-account-plus f-20 text-gr-general" />
                        </div>
                    </el-tooltip>
                </div>
            </template>
        </div>
        <div class="row mx-0" style="height:calc(100% - 53px);">
            <div class="col-7 px-0 h-100 border-right">
                <div v-show="id_pedido !== null" class="mx-3 d-flex align-items-center" style="height:54px;">
                    <el-input v-model="buscar" size="small" clearable placeholder="Buscar producto" class="br-20" />
                </div>
                <div class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100% - 55px);">
                    <div class="row mx-0 justify-center">
                        <card-producto-pedido-ver
                        v-for="(prod, idx) in productos.filter(data => !buscar || data.producto.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="`prod-${idx}`"
                        :info="prod"
                        class="cr-pointer"
                        @accion="modalProducto"
                        />
                    </div>
                    <contentLoader v-show="loading" />
                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 px-0 h-100">
                <div class="row mx-0 border-bottom" style="height:47px;">
                    <div
                    class="col py-1 d-middle-center cr-pointer"
                    :class="tab=='detalle'? 'border-b-black text-general':''"
                    @click="tab='detalle'"
                    >
                        Detalle
                    </div>
                    <div
                    v-if="!_.isEmpty(pedidoCliente) && pedidoCliente.pago_estado != 1"
                    class="col py-1 d-middle-center cr-pointer"
                    :class="tab=='pago'? 'border-b-black text-general':''"
                    @click="tab='pago'"
                    >
                        Pago
                    </div>
                    <div
                    v-show="pedido.estado === 32"
                    class="col py-1 d-middle-center cr-pointer"
                    :class="tab=='reclamo'? 'border-b-black text-general':''"
                    @click="tab='reclamo'"
                    >
                        Reclamo
                    </div>
                </div>
                <div class="col px-0 overflow-auto custom-scroll" style="height:calc(100% - 48px)">
                    <detalle-historial v-show="tab === 'detalle' && id_pedido" :pedido="pedido" />
                    <pago v-show="tab === 'pago'" :pedido="pedidoCliente" :vista="3" />
                    <chat-reclamo v-if="tab === 'reclamo' && id_pedido" />
                </div>
            </div>
        </div>

        <modalDetalleProd ref="modalDetalleProd" />
        <modalCalificacionesPedidoAdmin ref="modalCalificacionesPedidoAdmin" />
        <modalProductoDesconocido ref="modalProductoDesconocido" />
        <modalChat ref="modalChat" :pedido="pedido" :leechero="cliente" @actualizarIcono="actualizarIconoChat" />
        <modal-devolver-prod ref="modalDevolverProd" :pedido="pedido" @update="listar_productos" />
        <modalOtorgarCredito ref="OtorgarCredito" @credito="listar_productos" />
        <modal-historial-productos ref="modalHistorialProductos" />
        <modal-imprimir ref="imprimirRecibo" />
        <modal-info-cancelado ref="modalInfoCancelado" />
        <modalInfoRechazado ref="modalInfoRechazado" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import PdfPedidos from '~/services/pdf/pdf'
export default {
    name: 'ListadoHistorialAdmin',
    components: {
        detalleHistorial: () => import('./detalleHistorial'),
        modalDetalleProd: () => import('../partials/modalDetalleProducto'),
        modalProductoDesconocido: () => import('../partials/modalProductoDesconocido'),
        modalCalificacionesPedidoAdmin: () => import('../partials/modalCalificacionPedido'),
        cardProductoPedidoVer: () => import('../partials/card-producto-pedido-ver'),
        modalDevolverProd: () => import('../partials/modalDevolverProducto'),
        modalOtorgarCredito: () => import('../partials/modalOtorgarCredito'),
        chatReclamo: () => import('./chatReclamo'),
        modalHistorialProductos: () => import('../partials/modalHistorialProductos'),
        modalInfoCancelado: () => import('../partials/ModalInfoCancelado'),
        modalInfoRechazado: () => import('../partials/ModalInfoRechazado'),
        pago: () => import('~/pages/almacen/tendero/historial/pago.vue'),
    },
    data(){
        return {
            tab:'detalle',
            editar: false,
            verDetalle: 0,
            estadoPedido: { estado: 'Reportado', icono: 'icon-cancel-circled-outline' },
            loading:false,
            buscar:''

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_admin/id_pedido',
            productos: 'pedidos/pedidos_historial_admin/productos',
            cliente: 'pedidos/pedidos_historial_admin/cliente',
            pedido: 'pedidos/pedidos_historial_admin/pedido',
            pedidoCliente: 'pedidos/pedidos_historial_admin/pedidoCliente',
            pedidos: 'pedidos/pedidos_historial_admin/pedidos', // solo para actualizar icono chat
        }),
    },
    watch:{
        id_pedido(val){
            if(val){
                this.listar_productos()
                this.buscar = ''
            }else{
                this.$store.dispatch('pedidos/pedidos_historial_admin/set_data')
            }
        }
    },
    destroyed(){
        this.$store.dispatch('pedidos/pedidos_historial_admin/set_data')
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                await this.$store.dispatch('pedidos/pedidos_historial_admin/pedidos_productos',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_historial_admin/pedidos_historial',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_historial_admin/pedidos_resumenes', this.id_pedido)
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        historialDevueltos(){
            this.$refs.modalHistorialProductos.toggle(1)
        },
        historialEliminados(){
            this.$refs.modalHistorialProductos.toggle(2)
        },
        modalProducto(prod){
            if(prod.desconocido === 1){
                this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                return
            }
            this.$refs.modalDetalleProd.datos_cambio(prod) // modal detalle producto
        },
        open_calificaciones(){
            this.$refs.modalCalificacionesPedidoAdmin.toggle(this.id_pedido)
        },
        verChat(){
            let soloVisual = !!this.pedido.fecha_finalizado_chat
            this.$refs.modalChat.toggle(this.id_pedido, 5, soloVisual,1) // 5 = tipo pedido admin
        },
        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos/set_pedido', {...this.pedido, cant_mensajes: 0})
            // para actualizar el listado de pedidos
            let idx_pedido = this.pedidos.findIndex(e => e.id === this.id_pedido)

            if(idx_pedido >= 0){
                let found = this.pedidos[idx_pedido]
                found.cant_mensajes = 0
            }
        },
        accionBoton(key){
            switch (key){
            case 1:
                this.$refs.OtorgarCredito.toggle()
                break;
            case 2:
                this.$refs.modalDevolverProd.toggle(this.productos, this.id_pedido)
                break;
            default:
                break;
            }
        },
        async imprimir(){
            try {
                if (this.pedido.id_pedido == null){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                    return
                }
                this.$refs.imprimirRecibo.toggle(this.id_pedido, this.pedido.id_pedido);

            } catch (e){
                this.error_catch(e)
            }
        },
        irDetalleCancelado(){
            this.$refs.modalInfoCancelado.toggle(this.pedido.cancelado_motivo, this.pedido.cancelado_justificacion);
        },
        irDetalleRechazado(){
            this.$refs.modalInfoRechazado.toggle(this.pedido.cancelado_motivo, this.pedido.cancelado_justificacion);
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
.br-12{
    border-radius: 12px !important;
}
</style>
